import axios from "axios";
import config from "@config/config";

const process = async (
  sfId: string,
  payUConfig,
  payUResponse: any
): Promise<any> => {
  const processEndpoint = config.serverlessUrl + "payment/payu/process";
  const response = await axios.post(processEndpoint, {
    sfId,
    providerId: payUConfig.id,
    response: payUResponse,
  });
  return response.data;
};

export default process;
