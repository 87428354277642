import axios from "axios";
import config from "@config/config";

const getPayuClientConfig = async (sfId, providerId, order) => {
  const transactionHashEndpoint = config.serverlessUrl + "payment/payu/create";
  const response = await axios.post(transactionHashEndpoint, {
    sfId,
    order,
    providerId,
  });
  return response.data;
};

export default getPayuClientConfig;
