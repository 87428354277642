<template>
  <div>PayU</div>
</template>

<script>
import { mapGetters } from "vuex";
import shortUid from "@lib/utility/shortUid";

export default {
  props: {
    key: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    productsDescription: {
      type: String,
      required: true,
    },
    payerName: {
      type: String,
      required: true,
    },
    payerEmail: {
      type: String,
      required: true,
    },
    payerPhone: {
      type: String,
      required: true,
    },
    serverHash: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("store", "primaryColor", "storeLogo"),
  },
  async mounted() {
    // First set viewport
    let metaTag = document.querySelector("meta[name='viewport']");
    metaTag.setAttribute(
      "content",
      "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
    );

    let clientId = appSettings.paypalClientId;
    let payuScript = document.createElement("script");
    payuScript.setAttribute("id", "bolt");
    payuScript.setAttribute(
      "src",
      "https://checkout-static.citruspay.com/bolt/run/bolt.min.js"
    );
    payuScript.setAttribute("bolt-color", this.primaryColor);
    payuScript.setAttribute("bolt-logo", this.storeLogo);

    const key = this.store.integrations;
    const txnid = shortUid();
    const amount = this.amount;
    const productInfo = this.productsDescription;
    const firstName = this.payerName.trim().split(" ")[0].toLowerCase();
    const email = this.payerEmail;
    const phone = this.payerPhone;
    const surl = "/ordersuccess";
    const furl = "/paymentfailure";
    const hash = this.serverHash;
    let self = this;

    payuScript.onload = function () {
      var RequestData = {
        key,
        txnid,
        hash,
        amount,
        firstname,
        email,
        phone,
        productinfo,
        surl,
        furl,
        mode,
      };
      let paypal = window.paypal;

      paypal
        .Buttons({
          createOrder: async function () {
            return orderId;
          },
          onApprove: function (data, actions) {
            // eslint-disable-next-line no-console
            console.log("Payment on approve triggerred");
            return actions.order.capture().then((details) => {
              self.onPaymentApprove(details);
            });
          },
          onCancel: function (payload) {
            // eslint-disable-next-line no-console
            console.log(payload);
            self.onPaymentCancel(payload);
          },
          onError: function (payload) {
            // eslint-disable-next-line no-console
            console.log(payload);
            self.onPaymentError(payload);
          },
        })
        .render("#paypal-button-container");
    };
    document.head.appendChild(paypalScript);
  },
};
</script>
