// Register all payment modules - primarily stores and event listeners
// Pre registration hook
import { StorefrontModule } from "@lib/appRegister";
import { payUStore } from "./store";

export const PayUModule: StorefrontModule = function ({
  app,
  store,
  router,
  moduleConfig,
  appConfig,
}) {
  store.registerModule("payment/payu", payUStore);
  store.dispatch("payment/payu/init");
};
