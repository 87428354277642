declare global {
  interface Window {
    bolt: any;
  }
}

const initiateUi = async (clientConfig, handler, primaryColor, logo) => {
  // First set viewport
  const metaTag = document.querySelector("meta[name='viewport']");
  metaTag.setAttribute(
    "content",
    "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
  );
  const payuScript = document.createElement("script");
  payuScript.setAttribute("id", "bolt");
  payuScript.setAttribute(
    "src",
    "https://checkout-static.citruspay.com/bolt/run/bolt.min.js"
  );
  payuScript.setAttribute("bolt-color", primaryColor.substr(1, 6));
  payuScript.setAttribute("bolt-logo", logo);

  payuScript.onload = function () {
    const boltUi = window.bolt;
    boltUi.launch(clientConfig, handler);
  };
  document.head.appendChild(payuScript);
};

export default initiateUi;
