import { Module } from "vuex";
import initiateUi from "../helpers/initiateUi";
import process from "../helpers/process";
import getPayuClientConfig from "../services/getClientConfig";

export const payUStore: Module<any, any> = {
  namespaced: true,
  state: {
    storeId: null,
  },
  getters: {
    storeId: (state) => state.storeId,
  },
  mutations: {
    setStoreId(state, storeId) {
      state.storeId = storeId;
    },
  },
  actions: {
    init({ commit, dispatch, rootState }) {
      commit("setStoreId", rootState.storeId);
    },
    async launch({ getters, rootGetters, dispatch }, { order, config }) {
      let orderStatus = {
        status: "initialized",
        order: null,
        error: null,
      };
      const sfId = getters.storeId;
      const clientConfig = await getPayuClientConfig(sfId, config.id, order);
      return new Promise((resolve, reject) => {
        try {
          const payuHandler = {
            responseHandler: async function ({ response }) {
              if (response && response.txnStatus === "CANCEL") {
                orderStatus.status = "error";
                orderStatus.error = "Payment Cancelled";
              } else {
                orderStatus = await dispatch("processResponse", {
                  config,
                  response,
                });
              }
              resolve(orderStatus);
            },
            catchException: function (error) {
              orderStatus.status = "error";
              orderStatus.error =
                "Could not place your order. Please try again after some time.";
              console.error(error);
              reject(orderStatus);
            },
          };
          initiateUi(
            clientConfig,
            payuHandler,
            rootGetters.primaryColor,
            rootGetters.storeLogo
          );
        } catch (error) {
          orderStatus.status = "error";
          orderStatus.error =
            "Could not place your order. Please try again after some time.";
          console.error(error);
          reject(orderStatus);
        }
      });
    },
    async processResponse({ getters }, { config, response }) {
      const orderDetails = await process(getters.storeId, config, response);
      return orderDetails;
    },
  },
};
